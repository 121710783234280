import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { graphql, useFragment } from 'react-relay';
import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { ExitingPersistence, FadeIn } from '@atlaskit/motion';
import Popup from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	UNSAFE_DO_NOT_USE_BANNER_HEIGHT,
	UNSAFE_DO_NOT_USE_TOP_NAVIGATION_HEIGHT,
} from '@atlassian/jira-navigation-system/src/index.tsx';
import {
	fireUIAnalytics,
	useAnalyticsEvents,
	DRAWER,
	ContextualAnalyticsData,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { useProjectCreateDrawer } from '@atlassian/jira-project-create-drawer-context/src/index.tsx';
import type { ui_projectsDirectoryTemplatesSidebar_RecommendationsInProjectList$key } from '@atlassian/jira-relay/src/__generated__/ui_projectsDirectoryTemplatesSidebar_RecommendationsInProjectList.graphql';
import { useEnvironment } from '@atlassian/jira-tenant-context-controller/src/components/environment/index.tsx';
import { useFireCrossFlowAnalyticsEvents } from '@atlassian/surface-analytics';
import {
	PROJECT_CREATE_SOURCE,
	SIDEBAR_WIDTH,
	getProductTypeCcpReferenceIdMapForEnv,
} from '../constants.tsx';
import { messages } from '../messages.tsx';
import { useSetProjectListRightPanelState } from '../services/set-right-panel-state/index.tsx';
import { TemplateCard } from './card/index.tsx';
import { TemplatePreview } from './preview/index.tsx';
import { JiraProjectListSidePanelSkipLink } from './skip-link/index.tsx';

type Props = {
	rootQueryRef: ui_projectsDirectoryTemplatesSidebar_RecommendationsInProjectList$key;
};

const RecommendationsInProjectList = ({ rootQueryRef }: Props) => {
	const [templatePreviewKey, setTemplatePreviewKey] = useState<string | null>(null);
	const environment = useEnvironment();
	const { fireViewedUIEvent } = useFireCrossFlowAnalyticsEvents();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const {
		methods: { open: openProjectCreate },
	} = useProjectCreateDrawer();
	const buttonEvent = createAnalyticsEvent({
		action: 'clicked',
		actionSubject: 'button',
	});
	const { formatMessage } = useIntl();

	const { projectListViewTemplates, userPreferences } =
		useFragment<ui_projectsDirectoryTemplatesSidebar_RecommendationsInProjectList$key>(
			graphql`
				fragment ui_projectsDirectoryTemplatesSidebar_RecommendationsInProjectList on JiraQuery {
					projectListViewTemplates(cloudId: $cloudId, experimentKey: "sidebarExperiment")
						@optIn(to: "JiraProjectListViewTemplate") {
						nodes {
							key @required(action: THROW)
							templateType @required(action: THROW)
							productKey @required(action: THROW)
							isProductLicensed @required(action: THROW)
							...card_projectsDirectoryTemplatesSidebar_TemplateCard
							...preview_projectsDirectoryTemplatesSidebar_TemplatePreview
						}
					}
					userPreferences(cloudId: $cloudId) @optIn(to: "JiraUserPreferences") {
						__id
					}
				}
			`,
			rootQueryRef,
		);

	const setProjectListRightPanelState = useSetProjectListRightPanelState(userPreferences?.__id);

	const onCloseClick = useCallback(() => {
		fireUIAnalytics(buttonEvent, 'closeButton');
		setProjectListRightPanelState('CLOSED');
	}, [buttonEvent, setProjectListRightPanelState]);

	const templates = useMemo(
		() =>
			projectListViewTemplates?.nodes?.filter(
				<T,>(template: T | undefined | null): template is T => !!template,
			) ?? [],
		[projectListViewTemplates?.nodes],
	);

	const templatePreviewRef = useMemo(
		() => templates.find((template) => template.key === templatePreviewKey),
		[templatePreviewKey, templates],
	);

	const recommendedProductIds = useMemo(
		() =>
			Array.from(
				templates.reduce((acc, template) => {
					const { isProductLicensed, productKey } = template;
					if (!isProductLicensed) {
						acc.add(getProductTypeCcpReferenceIdMapForEnv(environment)[productKey]);
					}
					return acc;
				}, new Set<string>()),
			),
		[environment, templates],
	);

	const onMoreTemplatesClick = useCallback(() => {
		fireUIAnalytics(buttonEvent, 'moreTemplatesButton');
		openProjectCreate({ showExperienceSelection: false, source: PROJECT_CREATE_SOURCE });
	}, [buttonEvent, openProjectCreate]);

	useEffect(() => {
		recommendedProductIds.length > 0 &&
			fireViewedUIEvent(
				{
					action: 'viewed',
					actionSubject: 'section',
					actionSubjectId: 'projectsDirectorySidebar',
				},
				{
					recommendedProductIds,
					source: 'projectsDirectorySidebarSection',
				},
			);
	}, [fireViewedUIEvent, recommendedProductIds]);

	return (
		<Popup
			isOpen
			placement="left-start"
			offset={[0, 0]}
			autoFocus={false}
			trigger={(triggerProps) => (
				<div {...triggerProps} aria-expanded={undefined}>
					<Box id="jira-project-list-side-panel" xcss={SidebarContainerStyles}>
						<Stack xcss={ExperimentSidebarContentStyles}>
							<Stack>
								<Inline xcss={TitleStyles} alignBlock="center" spread="space-between">
									<Heading size="xxsmall" as="div">
										{formatMessage(messages.title)}
									</Heading>
									<Box xcss={ButtonBoxStyles}>
										<Button
											iconBefore={<CrossIcon spacing="spacious" label="" />}
											aria-label={formatMessage(messages.closeButton)}
											data-testId="projects-directory-templates-sidebar.ui.close-templates-panel"
											appearance="subtle"
											onClick={onCloseClick}
										/>
									</Box>
								</Inline>
								<Box as="p" xcss={SubtitleStyles}>
									{formatMessage(messages.subtitle)}
								</Box>
							</Stack>
							{templates.map((cardRef, index) => (
								<TemplateCard
									key={cardRef.key}
									index={index}
									dataRef={cardRef}
									recommendedProductIds={recommendedProductIds}
									setTemplatePreviewKey={setTemplatePreviewKey}
								/>
							))}
							<Inline>
								<Button
									appearance="link"
									spacing="none"
									label={formatMessage(messages.moreTemplatesLink)}
									onClick={() => onMoreTemplatesClick()}
								>
									{formatMessage(messages.moreTemplatesLink)}
								</Button>
							</Inline>
						</Stack>
						<FireScreenAnalytics
							attributes={{
								templates: templates.map(({ key }) => key),
								templateTypes: templates.map(({ templateType }) => templateType),
							}}
						/>
						<JiraProjectListSidePanelSkipLink />
					</Box>
				</div>
			)}
			content={({ update }) => (
				<JSErrorBoundary
					id="template-preview-wrapper"
					packageName="jiraGrowthRecommendationsInProjectList"
				>
					<ExitingPersistence>
						{templatePreviewRef && (
							<FadeIn duration={500}>
								{(fadeInProps) => (
									<div {...fadeInProps}>
										<TemplatePreview update={update} previewRef={templatePreviewRef} />
									</div>
								)}
							</FadeIn>
						)}
					</ExitingPersistence>
				</JSErrorBoundary>
			)}
		/>
	);
};

const RecommendationsInProjectListContainer = (props: Props) => (
	<ContextualAnalyticsData sourceName="projectListTemplates" sourceType={DRAWER}>
		<RecommendationsInProjectList {...props} />
	</ContextualAnalyticsData>
);

const TitleStyles = xcss({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	textTransform: 'uppercase',
	color: 'color.text.subtle',
	position: 'relative',
});

const ButtonBoxStyles = xcss({
	paddingInline: 'space.050',
});

const SubtitleStyles = xcss({
	color: 'color.text.subtlest',
	font: token('font.body'),
	marginTop: 'space.0',
});

const ExperimentSidebarContentStyles = xcss({
	height: '100%',
	overflow: 'auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${SIDEBAR_WIDTH}px`,
	padding: 'space.300',
	backgroundColor: 'elevation.surface.sunken',
	boxSizing: 'border-box',
});

const SidebarContainerStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `calc(100vh - ${UNSAFE_DO_NOT_USE_TOP_NAVIGATION_HEIGHT} - ${UNSAFE_DO_NOT_USE_BANNER_HEIGHT})`,
});

export default RecommendationsInProjectListContainer;
